const state = {
  show_modal : false,
  attendees_list : {},
  search_attendee: '',
  search_sponsor_type: '',
  total_attendees: 0,
  attendee_start_from: 0
}
const actions = {
  fetchAttendees({commit,state}){
    axios.get("/attendee/list?search="+state.search_attendee+"&start_from="+state.attendee_start_from+"&sponsor_type="+state.search_sponsor_type)
    .then(function (response) {
      let res_data = {}
      if(state.attendee_start_from > 0) {
        state.attendees_list.push(...response.data.data)
        res_data = {
          key : 'attendees_list',
          data : state.attendees_list
        }
      } else {
        res_data = {
          key : 'attendees_list',
          data : response.data.data
        }
      }
      commit('setData',res_data)
      commit('setData',{key : 'total_attendees',data : response.data.totalRecord})
    })
    .catch(function (e) {
      console.log(e);
    });
  }
}
const getters = {}
const mutations = {
  setData(state,data){
    state[data.key] = data.data
  },
}
import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}