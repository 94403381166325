<template>
  <v-container class="pa-0">
    <v-card tile class="rounded-lg">
      <v-card :loading="loading" tile flat class="mx-auto" max-width="600">
        <v-card-text>
          <v-text-field
              type="password"
              v-model="current_password"
              :error-messages="form_error.current_password"
              label="Current Password"
              outlined
              dense
          ></v-text-field>
          <v-text-field
              type="password"
              v-model="new_password"
              :error-messages="form_error.new_password" label="New Password"
              outlined
              dense
          ></v-text-field>
          <v-text-field
              type="password"
              v-model="confirm_password"
              :error-messages="form_error.confirm_password"
              label="Confirm Password"
              outlined
              dense
          ></v-text-field>
          <v-btn
              class="mr-4"
              color="secondary"
              @click="updateProfile"
              :loading="loading"
              :disabled="loading"
          > Update
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/helper/axios";
import { mapMutations } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      current_password: null,
      new_password: null,
      confirm_password: null,
      loading: false,
      data: {},
      user_data:{}
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateProfile() {
      let _self = this;
      this.loading = "secondary";
      axios.post(`change_password`, {
        current_password: this.current_password,
        new_password: this.new_password,
        confirm_password: this.confirm_password,
      }).then((response) => {
        _self.form_error = {};
        let response_data = response.data;
        if (response_data.status) {
          this.data = response_data.user_data;
          localStorage.setItem("churchil_user", JSON.stringify(response_data.user_data));
          _self.setAlert({
            show: true,
            variant: "success",
            message: response.data.message,
            dismissCountDown: 5000,
          });
          this.user_data = response_data.user_data;
        } else {
          let errors = response_data.error;
          for (let key in errors) {
            console.log(errors[key]);
            _self.form_error[errors[key].field] = errors[key].message;
          }
        }
        _self.loading = false;
      }).catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
  },
  mounted: function () {
    this.user_data = JSON.parse(localStorage.getItem("flaacos_admin"));
    this.name = this.user_data.full_name;
    this.email = this.user_data.email;
  },
};
</script>