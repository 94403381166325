<template>
  <v-app id="inspire" class="blue lighten-5">
    <v-main
      :style="{
        backgroundImage: this.pageBackgroundImage,
        backgroundSize: 'cover',
      }"
    >
      <router-view />
    </v-main>
    <v-card>
      <v-footer
        padless
        class="rounded-t-lg mx-auto"
        fixed
        shaped
        max-width="850px"
      >
        <div class="d-flex align-center justify-start col-2 pa-0">
          <v-card class="px-0 pr-2 py-1 ml-2" to="/" flat width="150" height="60">
            <v-img
              :src="FRONT_ASSETS + 'frontend/img/RECongress_logo.png'"
              contain
            ></v-img>
          </v-card>
        </div>
        <v-tabs
          icons-and-text
          class="rounded-t-lg col-10 pa-0"
          :show-arrows="true"
          centered
          mobile-breakpoint="850"
        >
          <v-tab
            :to="menu.route ? menu.route : ''"
            :href="menu.href ? menu.href : ''"
            :target="menu.target"
            v-for="(menu,index) in menus"
            :key="menu.title"
            @click="showPopup(menu.is_popup)"
            :class="menu.class"
            style="font-size: 0.75rem"
          >
            {{ menu.title }}
            <v-badge :color="(online_status) ? 'green' : 'red'" dot top v-if="index == 5">
              <v-avatar size="35" tile>
                <v-img :src="FRONT_ASSETS + 'frontend/' + menu.icon"></v-img>
              </v-avatar>
            </v-badge>
            <v-avatar size="35" tile v-else>
                <v-img :src="FRONT_ASSETS + 'frontend/' + menu.icon"></v-img>
              </v-avatar>
          </v-tab>
        </v-tabs>
      </v-footer>
    </v-card>
    <Alert />
    <Notifications />
    <TotebagPopup />
    <AgendaPopup />
    <v-btn
      elevation="2"
      color="primary"
      dark
      fab
      fixed
      bottom
      right
      v-if="myself.heldesk_data"
      @click="openInfoChat"
    >
      <v-icon>mdi-message-bulleted</v-icon>
    </v-btn>
  </v-app>
</template>
<script>
import axios from "@/helper/axios";
import Alert from "@/components/Alert.vue";
import Notifications from "../afterAuth/Notifications";
import TotebagPopup from "@/components/Totebag.vue";
import AgendaPopup from "@/components/Agenda.vue";
import WsSubscriptions from "../../helper/websocket";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
export default {
  props: {
    source: String,
  },
  components: {
    Alert,
    Notifications,
    TotebagPopup,
    AgendaPopup,
  },
  data() {
    return {
      selected_slide: 0,
      attendee_list: [],
      menus: [
        {
          title: "Vendor Hall",
          route: "/",
          icon: "img/vendor_hall.svg",
          class: "v-step-2",
          is_popup: false,
        },
        {
          title: "Networking Room",
          route: "/networking-room",
          icon: "img/networking_room.svg",
          is_popup: false,
          class: "v-step-3",
        },
        {
          title: "Info desk",
          route: "/info-desk",
          icon: "img/info_desk.svg",
          is_popup: false,
          class: "v-step-4",
        },
        {
          title: "Agenda",
          route: "",
          icon: "img/agenda.svg",
          is_popup: "agenda",
          class: "v-step-5",
        },
        {
          title: "Tote Bag",
          route: "",
          icon: "img/tote_bag.svg",
          is_popup: "totebag",
          class: "v-step-6",
        },
        {
          title: "Profile",
          route: "/profile",
          icon: "img/user.svg",
          is_popup: false,
          class: "v-step-7",
        },
      ],
      object_list: [],
      chat_url: document.location.origin + "/#/info-desk-chat-admin",
      subscription: null,
    };
  },
  watch: {
    online_status: {
      handler(val) {
        if (val) {
          this.subscribeSocket();
          this.setMeOnline()
        } else {
          this.unSubscription();
        }
      },
    },
  },
  computed: {
    ...mapGetters("notifications", ["existingPrivateMembers"]),
    ...mapState("notifications", ["is_msg_expand", "incoming_rintone"]),
    ...mapState("utils", ["maxWidth", "pageBackgroundImage", "online_status"]),
    pageTitle() {
      return this.$route.meta.title;
    },
    myself() {
      return JSON.parse(localStorage.getItem("churchil_user"));
    },
  },
  methods: {
    ...mapActions("notifications", ["getPrivateMessages"]),
    ...mapMutations("attendees", ["setData"]),
    ...mapMutations("totebag", ["setTotebagData"]),
    ...mapMutations("agenda", ["setAgendaData"]),
    ...mapMutations("notifications", [
      "addNotification",
      "updateUnreadCounter",
    ]),
    logout() {
      localStorage.removeItem("churchil_user");
      this.$router.push("/login");
    },
    showPopup(title) {
      if (title == "attendee") {
        this.setData({
          key: "show_modal",
          data: true,
        });
      }
      if (title == "totebag") {
        this.setTotebagData({
          key: "show_totebag_modal",
          data: true,
        });
      }
      if (title == "agenda") {
        this.setAgendaData({
          key: "show_agenda_modal",
          data: true,
        });
      }
    },
    setBoothNotification(data) {
      data.type = "booth_visit";
      this.addNotification({
        data: data,
        color: "purple",
        position: "",
      });
      this.incoming_rintone.play();
    },
    setNotification(data) {
      if (data.type == "private_message") {
        if (data.send_by != this.myself.attendee_id) {
          let existingMember = this.existingPrivateMembers.filter(
            (eAttendee) => {
              return eAttendee.attendee_id == data.send_by;
            }
          ).length;
          if (existingMember == 0) {
            this.getPrivateMessages(false);
          }
          //show popup if chatbox not expanded
          if (this.is_msg_expand != 0) {
            if (existingMember != 0) {
              this.updateUnreadCounter(data);
            }
            this.addNotification({
              data: data,
              color: "blue",
            });
          }
          if (this.is_msg_expand == 0 && existingMember != 0) {
            this.updateUnreadCounter(data);
          }
        }
      }
    },
    setGeneralNotification(data) {
      data.no_timeout = true;
      this.addNotification({
        data: data,
        color: "green darken-3",
        max_width: "500",
        position: "top-right",
      });
    },
    getObjects() {
      let _self = this;
      axios
        .get("object/get?page_name=HEADER_MENU")
        .then(function (response) {
          if (response.data.status) {
            _self.object_list = response.data.data;
          }
        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    openInfoChat() {
      window.open(
        this.chat_url,
        "info-desk-chat-admin",
        "location=yes,scrollbars=yes,status=yes"
      );
    },
    subscribeSocket() {
      this.subscription = this.$ws.socket.getSubscription(
        `user:${this.myself.attendee_id}`
      );
      if (!this.subscription) {
        this.$ws.subscribe(`user:${this.myself.attendee_id}`);
        this.$ws.$on(
          `user:${this.myself.attendee_id}|general_notification`,
          this.setGeneralNotification
        );
        this.$ws.$on(
          `user:${this.myself.attendee_id}|notification`,
          this.setNotification
        );
        this.$ws.$on(
          `user:${this.myself.attendee_id}|booth_visit`,
          this.setBoothNotification
        );
        this.$ws.$on(
          `user:${this.myself.attendee_id}|new_message`,
          this.setNotification
        );
      }
    },
    unSubscription() {
      this.$ws.$off(`user:${this.myself.attendee_id}|general_notification`);
      this.$ws.$off(`user:${this.myself.attendee_id}|notification`);
      this.$ws.$off(`user:${this.myself.attendee_id}|booth_visit`);
      this.$ws.$off(`user:${this.myself.attendee_id}|new_message`);
      let subscription = this.$ws.socket.getSubscription(
      `user:${this.myself.attendee_id}`
      );
      if (subscription) {
        subscription.close();
        this.$ws.socket.removeSubscription(`user:${this.myself.attendee_id}`);
      }
    },
    setMeOnline(){
      axios.get("/profile/set_online")
    },
    addSiteView() {
      axios
        .post("/add_site_view")
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
    }
  },
  async created() {
    this.getObjects();
    let user = JSON.parse(localStorage.getItem("churchil_user"));
    await WsSubscriptions(user.attendee_token);
    this.addSiteView();
  },
  beforeDestroy() {
    let subscription = this.$ws.socket.getSubscription(
      `user:${this.myself.attendee_id}`
    );
    if (subscription) {
      subscription.close();
      this.$ws.socket.removeSubscription(`user:${this.myself.attendee_id}`);
    }
  },
};
</script>
<style>
.cursor_pointer {
  cursor: pointer;
}
.name {
  text-transform: capitalize;
}
</style>
