const state = {
  vendor_details : {
    booth_color : '',
    social_links : {
      facebook:"",
      linkedin:"",
      twitter:"",
      instagram:"",
      youtube:"",
    },
  },
  vendor_social_xy : {
    facebook_x:358,
    linkedin_x:0,
    twitter_x:0,
    instagram_x:0,
    youtube_x:0,
  },
  vendor_objects: {}
}
const actions = {
  async getBoothsDetails({commit},details = false){
    await axios.get("/details")
    .then(function (response) {
      let vendor_data = response.data.data;
      if(details){
        let res_data = {
          key : 'vendor_details',
          data : vendor_data
        }
        commit('setBoothData',res_data)
      }
      let res_data1 = {
        key : 'vendor_objects',
        data : vendor_data.object_data
      }
      commit('setBoothData',res_data1)
    })
    .catch(function (e) {
      console.log(e);
    });
  }
}
const getters = {}
const mutations = {
  setSocialIcons(state,data){
    state.vendor_social_xy[data.key] = data.data;
  },
  setBoothData(state,data){
    state[data.key] = data.data
  }
}
import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}