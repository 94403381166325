import axios from 'axios'
let userdata = JSON.parse(localStorage.getItem('churchil_user'));
let token;
if(userdata && userdata.attendee_token!='' && userdata.attendee_token!=undefined){
    token = userdata.attendee_token;
}
axios.defaults.baseURL = `${process.env.VUE_APP_API_PATH}/user/`;
axios.defaults.headers.common = {
    "Authorization": "Bearer "+token,
    "Accept" : 'application/json'
};
// axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         localStorage.removeItem('churchil_user')
//         window.location = '/'
//     }
//     return error;
// });
export default axios