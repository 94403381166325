<template>
  <v-container class="pa-0" fluid ref="vendor-hall-container">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" viewBox="0 0 1920 1440">
      <image id="Background" x="-18" width="1950" height="1440" :xlink:href="main_img"/>
      <path class="cls-1 cursor_pointer" d="M692,782h537l44,190H648Z" @click="openUrl(object_list.ADVERTISE_2.link)"/>
      <rect x="745" y="335" width="425" height="84" id="return-session" class="cls-2 cursor_pointer" @click="openUrl(object_list.ADVERTISE_1.link)" />
      <rect id="ballroom-1" x="150" y="460" width="680" height="310" class="cls-2 cursor_pointer" @click="openLink(1)"/>
      <rect id="ballroom-2" x="1085" y="460" width="680" height="300" class="cls-2 cursor_pointer" @click="openLink(2)"/>
    </svg>
    <v-tour
      name="myTour"
      :steps="steps"
      :callbacks="tourCallbacks"
      :options="{ highlight: true }"
    >
    </v-tour>
    <v-dialog v-model="tour_dialog" max-width="300" persistent>
      <v-card color="primary">
        <v-card-text class="px-5 py-2">
          <span class="white--text body-1 text-justify"
            >Welcome to the tutorial for the Virtual Exhibit Hall Platform! This
            will cover some of the navigation items for the website.
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn small color="red" dark @click="onFinishTour()">skip</v-btn>
          <v-spacer></v-spacer>
          <v-btn small color="green" dark @click="startTour()">next</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <VendorDirectory />
    <v-speed-dial bottom fixed class="mb-16" right>
    <template v-slot:activator>
      <v-btn dark @click="showVendorDirectory" depressed color="orange">
        <v-icon left>mdi-view-list-outline</v-icon> {{$t('common.vendor_directory_btn')}}
      </v-btn>
    </template>
    </v-speed-dial>
  </v-container>
</template>
<script>
import VendorDirectory from "@/components/exhibitor/Directory.vue";
import { mapMutations } from "vuex";
import axios from "@/helper/axios";
export default {
  components: {
    VendorDirectory
  },
  data() {
    return {
      steps: [
        {
          target: ".v-step-2",
          content: `Clicking the Vendor Hall Icon will take you back to this page where you can see all of the Ballroom Entrances.`,
        },
        {
          target: ".v-step-3",
          content: `Clicking the Networking Room Icon will take you to a communal lounge for all of the Attendees and Exhibitors. Here you will be able to meet new friends and catch up with old ones from previous Congress events!`,
        },
        {
          target: ".v-step-4",
          content: `Clicking on the Info Desk Icon will take you to our help desk.  Here you can ask the agents any questions or issues you may be having on the platform. `,
        },
        {
          target: ".v-step-5",
          content: `Clicking on the Agenda Icon will bring up the schedule for ReCongress2022.  Please feel free to check out all the sessions the conference will be offering!`,
        },
        {
          target: ".v-step-6",
          content: `Clicking on the Tote Bag Icon will populate your personal tote bag!  Here you can add any documents you enjoyed viewing while visiting all of the booths.  You will then be able to download the documents and browse at your own pace. `,
        },
        {
          target: ".v-step-7",
          content: `Clicking the Profile Icon will navigate to the profile editing page.  Here you can update your information like name, password, profile picture, etc.`,
        },
        {
          target: ".v-step-8",
          content: `Clicking the Chat icon will show all of your current messages. You can now click on one of the Ballrooms and start visiting our Vendors!  `,
        },
      ],
      tourCallbacks: {
        onFinish: this.onFinishTour,
        onSkip: this.onFinishTour,
      },
      tour_dialog: false,
      object_list: {
        ADVERTISE_1: {},
        ADVERTISE_2: {},
      },
    };
  },
  computed: {
    main_img() {
      return process.env.VUE_APP_ASSETS + "RECongress22_vendor_hall.jpg";
    }
  },
  methods: {
    ...mapMutations("vendor_directory", ["setVendorData"]),
    startTour() {
      this.$tours["myTour"].start();
      this.tour_dialog = false;
    },
    openLink(room_id) {
      this.$router.push("/vendor-hall/" + room_id);
    },
    openUrl(url){
      window.open(url,'_self')
    },
    onFinishTour() {
      this.tour_dialog = false;
      localStorage.setItem("tour_completed", true);
    },
    showVendorDirectory() {
      this.setVendorData({
        key: "show_vendor_directory_modal",
        data: true,
      });
    },
    getVendorHallAdvertise(){
        axios
        .get("object/get?page_name=VENDOR_HALL_LIST")
        .then((response) => {
          let data = response.data
          if(data.status){
            for(let key in data.data){
              this.object_list[data.data[key].page_position[0].value] = data.data[key]
            }
          }
        })
        .catch(function (e) {
          console.log(e);
        });
    }
  },
  mounted() {
    let tour_completed = localStorage.getItem("tour_completed");
    if (!tour_completed) {
      this.tour_dialog = true;
    }
    this.getVendorHallAdvertise()
  },
};
</script>
<style type="text/css">
.cls-1, .cls-2 {
  fill: #fff;
  fill-opacity: 0;
}

.cls-1 {
  fill-rule: evenodd;
}
.dot {
  position: fixed;
  top: 50%;
  left: 50%;
}
.v-tour__target--highlighted {
  pointer-events: none !important;
}
.v-step__content {
  text-align: justify;
}
.v-step {
  background: #6a4ba2 !important;
}
.v-step[x-placement^="top"] .v-step__arrow {
  border-color: #6a4ba2 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
.v-step[x-placement^="bottom"] .v-step__arrow {
  border-color: #6a4ba2 !important;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}
</style>