import Vue from "vue";
import store from '../store/index'
export default async (token) => {
  return new Promise((resolve) => {
    Vue.ws.disconnect()
    Vue.ws.connect(
        {
            wsDomain: process.env.VUE_APP_SOCKET_URL,
            jwtToken: token
        },
        {
            path: process.env.VUE_APP_SOCKET_PATH,
            reconnectionAttempts: 300,
            reconnectionDelay: 5000
        }
    );
    Vue.ws.socket.on("open", () => {
      resolve()
      store.commit('utils/setOnlineStatus', true);
    });
    Vue.ws.socket.on("close", () => {
      store.commit('utils/setOnlineStatus', false);
    });
    Vue.ws.socket.on("error", () => {
      store.commit('utils/setOnlineStatus', false);
    });
  })
};