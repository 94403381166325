<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4">
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-img src="/icon.png" width="90%"></v-img>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-2" :loading="loading">
              <v-toolbar color="primary" dark flat dense>
                <v-toolbar-title>Reset Password</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      label="New Password"
                      name="email"
                      prepend-icon="mdi-form-textbox-password"
                      type="password"
                      v-model="new_password"
                      :error-messages="form_error.new_password"
                  ></v-text-field>
                  <v-text-field
                      label="Confirm Password"
                      name="email"
                      prepend-icon="mdi-form-textbox-password"
                      type="password"
                      v-model="re_password"
                      :error-messages="form_error.confirm_password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn dark text small color="primary" to="/login">
                  <v-icon dark>mdi-arrow-left</v-icon>
                  <span>Back to Login</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" v-on:click="resetPassword()" :loading="loading">Submit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Alert />
  </v-app>
</template>

<script>
import axios from "@/helper/axios";
import { mapMutations } from "vuex";
import Alert from "@/components/Alert";

export default {
  name: "Login",
  components : { Alert },
  data() {
    return {
      form_error: {},
      new_password: null,
      re_password: null,
      loading: false,
      id: null,
      token: null,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    resetPassword() {
      let _self = this;
      this.loading = "accent";
      axios.post('reset_password', {
        user_key: _self.id,
        user_token: _self.token,
        new_password: _self.new_password,
        confirm_password: _self.re_password,
      }).then((response) => {
        _self.form_error = {};
        let response_data = response.data;
        if (response_data.status) {
          _self.setAlert({
            show: true,
            variant: "success",
            message: response_data.message,
            dismissCountDown: 5000,
          });
          setTimeout(function (){
            window.location.href = "/login";
          },600)
        } else {
          _self.loading = false;
          let errors = response_data.error;
          for (let key in errors) {
            console.log(errors[key]);
            _self.form_error[errors[key].field] = errors[key].message;
          }
        }
      }).catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
  },
  mounted: function () {
    this.id = this.$route.params.id
    this.token = this.$route.params.token
  }
};
</script>