const state = {
  show_public_chat_modal : false,
  public_messages : {}
}
const actions = {
  fetchPublicChatMessage({commit,state}){
    this.$axios.get("/attendee/list?search="+state.search_attendee+"&start_from="+state.attendee_start_from)
    .then(function (response) {
      let res_data = {}
      if(state.attendee_start_from > 0) {
        state.attendees_list.push(...response.data.data)
        res_data = {
          key : 'attendees_list',
          data : state.attendees_list
        }
      } else {
        res_data = {
          key : 'attendees_list',
          data : response.data.data
        }
      }
      commit('setData',res_data)
      commit('setData',{key : 'total_attendees',data : response.data.totalRecord})
    })
    .catch(function (e) {
      console.log(e);
    });
  },
}
const getters = {}
const mutations = { 
  setPublicChatData(state,data) {
    state[data.key] = data.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}