<template>
  <v-dialog
    v-model="show_agenda_modal"
    :max-width="maxPopupWidth"
    @click:outside="closePopup"
    persistent
  >
    <v-card>
      <iframe :src="agendaLink" frameborder="0" style="width:100%;height:85vh"></iframe>
      <v-btn style="position:absolute;bottom:20px;left:40px" color="red" dark fab small @click="closePopup">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <div style="position:absolute;bottom:20px;right:60px">
         <v-btn dark fab small class="mr-2" @click="english = true" :color="(english) ? 'primary' : ''">
         Eng
        </v-btn>
        <v-btn dark fab small class="mr-2" @click="english = false" :color="(!english) ? 'primary' : ''">
          ESP
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "AgendaPopup",
  data() {
    return {
      selected : [],
      english : true
    };
  },
  watch: {
    show_aganda_modal(value) {
      if (value) {
        this.fetchAgenda();
      }
    },
  },
  computed: {
    ...mapState("agenda", ["show_agenda_modal", "agenda_list"]),
    ...mapState("utils", ["maxPopupWidth"]),
    agendaLink(){
      return `${process.env.VUE_APP_ASSETS}RECongress-Schedule${(this.english) ? '_en' : '_sp' }.pdf`
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    ...mapActions("agenda", ["fetchAgenda"]),
    ...mapMutations("agenda", ["setAgendaData"]),
    closePopup() {
      this.setAgendaData({
        key: "show_agenda_modal",
        data: false,
      });
      this.$router.push("/redirector?redirect=" + this.$route.path);
    },
    getTimeSlot(session) {
      return (
        this.moment(session.start_time).format("ddd, MMM Do h:mm a") +
        " - " +
        this.moment(session.end_time).format("h:mm a")
      );
    },
    addEvent(session_id) {
      let _self = this;
      this.loading = "secondary";
      axios.post(`session/add_event`, {session_id:session_id})
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    }
  },
  mounted: function () {
    // this.fetchAgenda();
  },
};
</script>