import router from "../router"
export default {
  openUrl : function(href){
    if(href){
      if(parseInt(href) > 0) {
        router.push("/vendor/"+href);
      } else {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href,
        }).click();
      }
    }
  },
}