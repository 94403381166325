<template>
<div>
  <v-dialog
    v-model="show_totebag_modal"
    :max-width="maxPopupWidth"
    @click:outside="closePopup"
    persistent
  >
    <v-card>
      <v-toolbar dark flat color="primary" dense>
        <v-toolbar-title>Tote Bag</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="9">
          <v-card flat>
            <!-- <v-card-subtitle class="pa-2">
              <v-tabs>
                <v-tab class="text-capitalize">{{$t('tote_bag.my_docs')}}</v-tab>
              </v-tabs>
            </v-card-subtitle> -->
            <v-card-text class="px-1 py-0">
              <v-list>
                <v-btn color="red darken-3" v-if="selected.length" dark @click="removeDocument">{{$t('action.delete')}}</v-btn>
                <div v-for="(item, index) in totebag_list" :key="index">
                  <v-list-item>
                    <v-checkbox v-model="selected" :value="item.tote_bag_id"></v-checkbox>
                    <v-btn color="primary" small class="mx-1" @click="addResourceView(item.resource.resource_id,'0')" :href="item.resource.file" target="_blank"> {{$t('action.view')}} </v-btn>
                    <v-list-item-content class="mx-4" v-if="item.content_type==2">
                      <v-list-item-title>{{item.resource.title}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon  v-if="item.resource.file_type != 'LINK'" @click="addResourceView(item.resource.resource_id,'1')" :href="getFileUrl(item.resource.file_key)" target="_blank">
                        <v-icon>mdi-download-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider
                    v-if="index != totebag_list.length - 1"
                  ></v-divider>
                </div>
                <div v-if="totebag_list.length == 0">
                  {{$t('common.not_found')}}
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <div class="d-flex align-center" :style="{height : '100%'}">
             <div class="d-flex flex-column self-center" style="width:100%">
                <v-card class="pa-2" outlined tile @click="$helper.openUrl(totebag_advertise.ADVERTISE_1_LINK)" :ripple="totebag_advertise.ADVERTISE_1_LINK!=null">
                  <v-img :src="totebag_advertise.ADVERTISE_1"/>
                </v-card>
                <v-card class="pa-2" outlined tile @click="$helper.openUrl(totebag_advertise.ADVERTISE_2_LINK)" :ripple="totebag_advertise.ADVERTISE_2_LINK!=null">
                  <v-img :src="totebag_advertise.ADVERTISE_2"/>
                </v-card>
             </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
  <v-dialog v-model="show_preview" max-width="50%">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0 py-0">
          <video :src="preview_data" width="100%" v-if="preview_data" controls controlsList="nodownload"></video>
        </v-card-text>
        <v-btn style="position:absolute;top:0px;right:10px" color="red" dark fab small @click="show_preview = false">          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
  </v-dialog>
</div>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "TotebagPopup",
  data() {
    return {
      selected : [],
      is_document: true,
      preview_data: null,
      show_preview: false,
      baseURL : axios.defaults.baseURL
    };
  },
  watch: {
    show_totebag_modal(value) {
      if (value) {
        this.fetchTotebag();
        this.fetchAdertisement()
      }
    },
    show_preview : function(value){
      if(!value){
       this.preview_data = null
      }
    }
  },
  computed: {
    ...mapState("totebag", ["show_totebag_modal", "totebag_list","totebag_advertise"]),
    ...mapState("utils", ["maxPopupWidth"]),
  },
  methods: {
  ...mapActions("totebag", ["fetchTotebag","fetchAdertisement"]),
    ...mapMutations("totebag", ["setTotebagData"]),
    ...mapMutations("utils", ["setAlert"]),
    addResourceView(resource_id, is_download) {
      let _self = this;
      axios
        .post("/resource/add_view",{resource_id: resource_id, is_download: is_download})
        .then(function () {

        })
        .catch(function (e) {
          console.log(e);
        });
      _self.loading = false;
    },
    closePopup() {
      this.setTotebagData({
        key: "show_totebag_modal",
        data: false,
      });
    },
    getTimeSlot(session) {
      return (
        this.moment(session.start_time).format("ddd, MMM Do h:mm a") +
        " - " +
        this.moment(session.end_time).format("h:mm a")
      );
    },
    addEvent(session_id) {
      let _self = this;
      this.loading = "secondary";
      axios.post(`session/add_event`, {session_id:session_id})
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    removeDocument(){
      let _self = this;
      this.loading = "secondary";
      axios.post(`tote_bag/delete`, {selected_id:this.selected})
      .then((response) => {
        let response_data = response.data;
        if (response_data.status) {
          _self.selected = []
          _self.fetchTotebag();
          _self.setAlert({
            show: true,
            variant: "success",
            message: response_data.message,
            dismissCountDown: 5000,
          });
        }
        else {
          let errors = response_data.error;
          for (let key in errors) {
            _self.setAlert({
              show: true,
              variant: "danger",
              message: errors[key].message,
              dismissCountDown: 5000,
            });
          }
        }
        _self.loading = false;
      })
      .catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    },
    openVideo(url){
      this.show_preview = true;
      this.preview_data = url;
    },
    getFileUrl(resource){
      return axios.defaults.baseURL +'file/download?file_id='+ resource
    }
  },
  mounted: function () {
    this.fetchTotebag();
    this.fetchAdertisement()
  },
};
</script>