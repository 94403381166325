import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/auth/Login.vue'
import ForgotPassword from "@/components/auth/Forgotpassword.vue";
import ResetPassword from "../components/auth/Resetpassword.vue";
import MainLayout from "../components/layout/Layout.vue";
import ChangePassword from "@/components/afterAuth/Changepassword";
import VendorHall from "@/components/vendor-hall/VendorHall";
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "Login",
    },
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      title: "Forgot Password",
    },
    component: ForgotPassword
  },
  {
    path: "/reset-password/:id/:token",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "Reset password",
    },
  },
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "Ballroom",
        component: VendorHall,
        meta: {
          title: "Main Lobby",
        },
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
          title: "ChangePassword",
        },
      },
      {
        path: "/vendor-hall/:room_id",
        name: "VendorHall",
        meta: {
          title: "VendorHall List",
        },
        component: () => import("../components/vendor-hall/VendorBooths"),
      },
      {
        path: "/vendor/:vendor_id",
        name: "VendorBooth",
        meta: {
          title: "Exhibit booth",
        },
        component: () => import("../components/vendor-hall/VendorBooth"),
      },
      {
        path: "/exhibitor/directory",
        name: "ExhibitorDirectory",
        meta: {
          title: "ExhibitorDirectory",
        },
        component: () => import("../components/exhibitor/Directory"),
      },
      {
        path: "/networking-room",
        name: "NetworkingRoom",
        meta: {
          title: "Networking Room",
        },
        component: () => import("../components/networking-room/List"),
      },
      {
        path: "/tote-bag",
        name: "ToteBag",
        meta: {
          title: "Tote Bag",
        },
        component: () => import("../components/tote-bag/List"),
      },
      {
        path: "/info-desk",
        name: "InfoDesk",
        meta: {
          title: "Info Desk",
        },
        component: () => import("../components/infodesk/Desk"),
      },
      {
        path: "/profile",
        name: "ProfileView",
        meta: {
          title: "Profile View",
        },
        component: () => import("../components/profile/view"),
      }
    ]
  },
  {
    path: "/info-desk-chat",
    name: "InfoDeskChat",
    meta: {
      title: "Info Desk Chat",
    },
    component: () => import("../components/infodesk/Chat"),
  },
  {
    path: "/info-desk-chat-admin",
    name: "InfoDeskChat",
    meta: {
      title: "Info Desk Chat",
    },
    component: () => import("../components/infodesk/ChatReply"),
  },
  {
    path: "/redirector",
    name: "redirector",
    meta: {
      title: "redirector",
    },
    component: () => import("../components/Redirector"),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/forgot-password",
    "/reset-password/" + to.params.id + "/" + to.params.token,
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem("churchil_user"));
  // console.log(authRequired,loggedIn)
  document.title = to.meta.title
  if (authRequired && !loggedIn) {
    next("/login");
  } else if (!authRequired && loggedIn) {
    next("/");
  } else {
    next();
  }
});
export default router
