const state = {
  show_totebag_modal : false,
  totebag_list : {},
  totebag_advertise : {},
}
const actions = {
  fetchTotebag({commit}){
    axios.get("/tote_bag/list")
    .then(function (response) {
      let res_data = {
        key : 'totebag_list',
        data : response.data.data
      }
      commit('setTotebagData',res_data)
    })
    .catch(function (e) {
      console.log(e);
    });
  },
  fetchAdertisement({commit}){
    axios.get("object/get?page_name=TOTE_BAG")
    .then(function (response) {
      let send_data = {};
      for (let key in response.data.data) {
        let data = response.data.data[key];
        send_data[data.page_position[0].value] = data.upload_url;
        send_data[data.page_position[0].value+'_LINK'] = data.link;
      }
      let res_data = {
        key : 'totebag_advertise',
        data : send_data
      }
      commit('setTotebagData',res_data)
    })
    .catch(function (e) {
      console.log(e);
    });
  }
}
const getters = {}
const mutations = {
  setTotebagData(state,data){
    state[data.key] = data.data
  }
}
import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}