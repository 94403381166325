<template>
  <v-card :loading="loading">
    <v-card-title>
       <v-text-field
        label="Search by name"
        clearable
        placeholder="John Doe"
        autofocus
        v-debounce:300ms="searchAttendee"
        v-model="search"
        hide-details=""
      ></v-text-field>
    </v-card-title>
    <v-card-text v-if="search">
      <div v-for="(item, index) in attendee_list" :key="index">
        <v-list-item class="pa-0">
          <v-list-item-avatar>
            <v-img
              :src="item.profile_pic"
              :lazy-src="FRONT_ASSETS + 'placeholder.png'"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="item.full_name" class="name"></v-list-item-title>
            <v-list-item-subtitle class="mt-1">
              {{ item.company_name
              }}<span v-if="item.designation"> - {{ item.designation }}</span>
              <span v-if="item.online_status">
                -
                <span
                  v-html="
                    $t('profile.online_status_list')[item.online_status - 1]
                  "
                ></span>
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-row>
              <v-btn icon @click="startChatWithAttendee(item)">
                <v-icon color="grey darken-3">mdi-message-text-outline</v-icon>
              </v-btn>
            </v-row>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index != attendee_list.length - 1"></v-divider>
      </div>
      <div v-if="search && !attendee_list.length">
        No attendee found
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "@/helper/axios";
import { mapMutations } from "vuex";
export default {
  name: "SearchAttendee",
  data() {
    return {
      attendee_list: [],
      search: "",
      loading: false,
      totalRecords: 0,
    };
  },
  methods: {
    ...mapMutations("notifications", ["setExpandBox","setTempPrivateMessage","setChatPrivate","setPrivateChatUser"]),
    searchAttendee() {
      this.loading = true;
      axios
        .get("/attendee/list?search=" + this.search)
        .then((response) => {
          if (response.data.data.length) {
            this.attendee_list = response.data.data.slice(0, 4);
          } else {
            this.attendee_list = []
          }
          this.totalRecords = response.data.totalRecord;
        })
        .catch(function (e) {
          console.log(e);
        })
        .then(() => {
          this.loading = false;
        });
    },
    startChatWithAttendee(attendee) {
      this.setChatPrivate(true)
      this.setTempPrivateMessage(attendee)
      this.setPrivateChatUser(attendee)
      this.setExpandBox(0)
      this.search = ''
      this.$emit("closeMenu")
    },
  },
};
</script>

<style>
</style>