<template>
  <v-app id="inspire">
    <v-main class="grey lighten-4">
      <v-row class="d-flex align-stretch justify-space-between" style="height:100%" dense>
        <v-col class="pa-0">
          <v-card
              height="100%"
              width="100%"
              class="text-center d-flex align-center"
              flat
          >
            <v-card-text>
              <v-img :src="FRONT_ASSETS + 'frontend/img/RECongress_logo.png'" contain max-height="150"></v-img>
              <div class="indigo--text pt-5">Fall 2022 RECongress Virtual Conference</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="pa-0">
          <v-card class="d-flex align-center justify-center" flat height="90%">
            <v-card flat width="400">
              <v-card-title>
                Welcome
              </v-card-title>
              <v-card-text>
                <v-alert dense outlined type="error" v-show="brand_live_error">
                  Unable to login with email
                </v-alert>
                <v-form>
                  <v-text-field
                      label="Email"
                      name="login"
                      type="text"
                      v-model="email"
                      outlined
                      dense
                      placeholder=" "
                      autofocus
                      :error-messages="form_error.email"
                      :class="{'mb-3':form_error.email}"
                  ></v-text-field>

                  <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      v-model="password"
                      :error-messages="form_error.password"
                      @keyup.enter.native="login()"
                      outlined
                      dense
                      placeholder=" "
                      :class="{'mb-3':form_error.password}"
                  ></v-text-field>
                  <v-btn
                      block
                      color="primary"
                      @click="login()"
                      :loading="loading"
                      :disabled="loading"
                  >Login
                  </v-btn>
                  <!-- <div class="text-center ma-2">
                    <span>OR</span>
                  </div>

                  <v-btn
                      block
                      color="primary"
                      :href="links.registration"
                      target="_blank"
                  >Register Now
                  </v-btn> -->
                  <!-- <v-btn class="text-capitalize pa-0 mt-3 float-right" text to="/forgot-password">Forgot Password?
                  </v-btn> -->
                </v-form>
              </v-card-text>

            </v-card>
          </v-card>
          <v-card class="d-flex align-end justify-center pa-2" flat height="10%">
            <v-card-text class="text-center">
              <a :href="links.agenda" target="_blank">Conference Agenda</a> | <a :href="links.home_page" target="_blank">RECongress Home Page</a>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
import axios from "@/helper/axios";

export default {
  name: "Login",
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
      links : {
        agenda : 'https://docs.google.com/document/d/e/2PACX-1vR8u_1AzamtOtyIn_ECPX9_P-fZhtOGWtyK4vidOTC826lHao0evGCWwmcr13c7__TQAfyMPbTv1M2w/pub',
        registration : process.env.VUE_APP_ASSETS + 'FLAACOs_Registration.pdf',
        home_page : 'http://recongress.org/'
      },
      data: {},
      brand_live_error : false
    };
  },
  methods: {
    login() {
      let _self = this;
      this.loading = true
      axios.post('/login',{
        email: this.email,
        password: this.password
      }).then((response) => {
        _self.form_error = {};
        let response_data = response.data;
        if (response_data.status) {
          this.data = response_data.user_data;
          localStorage.setItem("churchil_user",
          JSON.stringify(response_data.user_data));
          axios.defaults.headers.common = {
            Authorization: "Bearer " + response_data.user_data.attendee_token,
            Accept: "application/json",
          };
          _self.$router.push("/");
        } else {
          let errors = response_data.error;
          for (let key in errors) {
            console.log(errors[key]);
            _self.form_error[errors[key].field] = errors[key].message;
          }
        }
        _self.loading = false;
      }).catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    }
  },
  mounted : function(){
    if(this.$route.query.email){
      this.loading = true
      axios.post('/brandlive_login',{
        email : this.$route.query.email
      }).then((res) => {
        let data = res.data
        if(data.status){
          localStorage.setItem("churchil_user",
          JSON.stringify(data.user_data));
          axios.defaults.headers.common = {
            Authorization: "Bearer " + data.user_data.attendee_token,
            Accept: "application/json",
          };
          this.$router.push("/");
        } else {
          this.brand_live_error = true
        }
      }).catch(() => {

      }).then(() => {
        this.loading = false
      })
    }
  }
};
</script>