import axios from "@/helper/axios";
function setBoothVisit(vendorId,commit){
  let visitedBooths = localStorage.getItem('visited_booths')
  if(!visitedBooths){
    let visited = []
    visited.push(vendorId)
    visited = visited.join(',')
    localStorage.setItem('visited_booths',visited)
    commit('setPopup',true)
  } else {
    let visited = localStorage.getItem('visited_booths')
    visited = visited.split(',')
    if(!visited.includes(vendorId)){
      visited.push(vendorId)
      visited = visited.join(',')
      localStorage.setItem('visited_booths',visited)
      commit('setPopup',true)
    } else {
      commit('setPopup',false)
    }
  }
}
const state = {
  vendor_details : false,
  public_messages : {},
  show_intro_popup : false
}
const actions = {
  fetchVendor({commit},vendorId){
    commit('setVednor',{})
    axios.get("/vendor/details?vendor_id=" + vendorId)
    .then(function (response) {
      setBoothVisit(vendorId,commit)
      commit('setVednor',response.data.data)
    })
    .catch(function (e) {
      console.log(e);
    }).then(() =>{

    });

  },
}
const getters = {
}
const mutations = {
  setVednor(state,data){
    state.vendor_details = data
  },
  setPopup(state,flag) {
    state.show_intro_popup = flag
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}