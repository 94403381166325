<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="6" md="3">
            <v-img src="/icon.png" width="90%"></v-img>
          </v-col>
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-2" :loading="loading">
              <v-toolbar color="primary" dark flat dense>
                <v-toolbar-title>Forgot Password</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                      label="Email"
                      name="email"
                      prepend-icon="mdi-email-open-outline"
                      type="text"
                      v-model="email"
                      :error-messages="form_error.email"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn dark text small color="primary" to="/login">
                  <v-icon dark>mdi-arrow-left</v-icon>
                  <span>Back to Login</span>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="forgotPassword()" :loading="loading">Submit</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Alert />
  </v-app>
</template>

<script>
import Alert from "@/components/Alert";
import axios from "@/helper/axios";
import { mapMutations } from "vuex";

export default {
  name: "Login",
  components : { Alert },
  data() {
    return {
      form_error: {},
      email: null,
      password: null,
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    forgotPassword() {
      let _self = this;
      this.loading = 'accent';
      axios.post(`send_forgot_password_link`, {
        email: this.email,
      }).then((response) => {
        _self.form_error = {};
        let response_data = response.data;
        if (response_data.status) {
          _self.setAlert({
            show: true,
            variant: "success",
            message: response_data.message,
            dismissCountDown: 5000,
          });
          setTimeout(function (){
            _self.$router.push('/login')
          },3000)
        } else {
          _self.loading = false;
          let errors = response_data.error;
          for (let key in errors) {
            console.log(errors[key]);
            _self.form_error[errors[key].field] = errors[key].message;
          }
        }
      }).catch((e) => {
        console.log(e);
        _self.loading = false;
      });
    }
  },
};
</script>